import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TCombo & TFilterParams>('v1/Combo')

 
export const combo = {
	getList: (data: { id_Server: string }) => get<any>(``, { data :{...data} }),
}
