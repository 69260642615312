import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const getSaveLog = gql`
	mutation saveActionLog($input: ActionLogInput!) {
		saveActionLog(input: $input) {
			status
		}
	}
`

export const saveLog = {
	save: async (data: any) => {
		try {
			return await graphQLClient.request(getSaveLog, { ...data })
		} catch (error) {
			console.error('Error get list post:', error)
		}
	},
}
