import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const getCmsBlock = gql`
	query getCmsBlocks($identifiers: [String]) {
		cmsBlocks(identifiers: $identifiers) {
			items {
				content
				identifier
				title
			}
		}
	}
`

export const cmsBlock = {
	getCmsBlock: async (data: any, language?:string) => {
		try {
      language && graphQLClient.setHeader("store", language)
			return await graphQLClient.request(getCmsBlock, data)
		} catch (error) {
			console.error('Error get list post:', error)
		}
	}
}
