import type { AnyAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { persistReducer, persistStore } from 'redux-persist'
import type { ThunkDispatch } from 'redux-thunk'
import thunk from 'redux-thunk'
import commonSlice from './commonSlice'
import userSlice from './userSlice'
import userTemp from './userTemp'
import useSaveSlice from './useSaveSlice'

import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import { WebStorage } from 'redux-persist/lib/types'

// hàm để tạo storage persist local hoặc session để chạy ServerSideRendering
export function createPersistStorage(storage: 'local' | 'session'): WebStorage {
	const isServer = typeof window === 'undefined'
	// Returns noop (dummy) storage.
	if (isServer) {
		return {
			getItem() {
				return Promise.resolve(null)
			},
			setItem() {
				return Promise.resolve()
			},
			removeItem() {
				return Promise.resolve()
			}
		}
	}

	return createWebStorage(storage)
}

const persistConfig = {
	key: 'root',
	storage: createPersistStorage('local'),
	whitelist: ['user']
}
const userTempPersistConfig = {
	key: 'temp',
	storage: createPersistStorage('session'),
	blacklist: ['user']
}

const rootReducer = combineReducers({
	common: commonSlice,
	user: userSlice,
	userTemp: persistReducer(userTempPersistConfig, userTemp),
	save: useSaveSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk]
}) as any

export { store }
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
export const useAppDispatch = () => useDispatch<AppThunkDispatch>()
