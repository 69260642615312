import { gql } from 'graphql-request'
import { graphQLClientGet } from './graphql'
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TCombo & TFilterParams>('v1/Coupon')

export const GET_LIST_PROMOTION = gql`
	query getPromotinos {
		promotion {
			code
			description
			id
			name
			short_description
			sort_order
			type
		}
	}
`
export const coupon = {
	getInfoCoupon: (data: { id_server: string; id_store: string; code: string }) =>
		get<any>(``, {
			data: { ...data }
		}),
	getList: async () => await graphQLClientGet.request(GET_LIST_PROMOTION)
}
