import { gql } from 'graphql-request'
import { graphQLClientGet } from './graphql'

export const getListBanks = gql`
	query getListBanks($filter: BankFilterInput, $pageSize: Int, $currentPage: Int) {
		Banks(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
			items {
				account_holder
				account_number
				bank
				bank_id
				logo
				payment_method
				qr_code
				sort_order
			}
			page_info {
				current_page
				page_size
				total_pages
			}
			total_count
		}
	}
`

export const getSlider = gql`
	query getSlider($filter: SliderFilterInput, $pageSize: Int, $currentPage: Int) {
		Slider(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
			items {
				Banner {
					items {
						banner_id
						name
						media
            type
            link
					}
				}
				identifier
				slider_id
				title
			}
			total_count
		}
	}
`

export const getPromotionPopup = gql`
	query getSlider($filter: SliderFilterInput, $pageSize: Int, $currentPage: Int) {
		Slider(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
			items {
				Banner {
					items {
						banner_id
						name
						media
					}
				}
				identifier
				slider_id
				title
			}
			total_count
		}
	}
`
export const getListLinks = gql`
	query getListLink {
		linksCmsPage {
			identifier
			label
			url_key
		}
	}
`

export const bank = {
	getListBank: async () => {
		try {
			return await graphQLClientGet.request(getListBanks)
		} catch (error) {
			console.error('Error get list post:', error)
		}
	}
}
export const homeApi = {
	getSlider: async (identifier: string) => {
		try {
			return await graphQLClientGet.request(getSlider, {
				filter: {
					identifier: { eq: identifier }
				}
			})
		} catch (error) {
			console.error('Error get list post:', error)
		}
  },
  getPromotionPopup: async (identifier: string) => {
		try {
			return await graphQLClientGet.request(getPromotionPopup, {
				filter: {
					identifier: { eq: identifier }
				}
			})
		} catch (error) {
			console.error('Error get list post:', error)
		}
  },
  getListLinks: async () => {
    try {
      return await graphQLClientGet.request(getListLinks)
    } catch (error) {
      console.error('Error get list link:', error)
    }
  }
}
