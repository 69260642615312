import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<number & TFilterParams>('v1/MaxTicket')

export const maxTicket = {
	getList: () => get<number>(``)
}
