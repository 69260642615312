import { gql } from 'graphql-request'
import { graphQLClientGet } from './graphql'

export const getToken = gql`
	query getKingProToken {
		getKingProToken {
			expired_at
			token
		}
	}
`
var tokenCache: any = null
export const token = {
	getToken: async () => {
    try {
			const checkExpired = Number(tokenCache?.getKingProToken?.expired_at) > Math.floor(Date.now() / 1000)
			if (tokenCache && checkExpired) return tokenCache
			tokenCache = await graphQLClientGet.request(getToken)
			return tokenCache
		} catch (error) {
			console.error('Error get list post:', error)
		}
	}
}
