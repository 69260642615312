import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { post } = new BaseAPI<TBooking & TFilterParams>('v1/BookingConfirm')

export const bookingConfirm = {
	create: (data: any) => post<TBooking[]>(``, data)
}
