import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TMovie & TFilterParams>('v1/Area')
const GetListArea = gql`
	{
		areas {
			id
			maps
			name_en
			name_vn
			orderby
		}
	}
`

export const area = {
  getList: () => get<TMovie[]>(``),
  getListGQL: async () => await graphQLClient.request(GetListArea) 
}
