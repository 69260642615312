import { PayloadType } from '@/types'
import { createSlice } from '@reduxjs/toolkit'

interface UseSaveSlice {
	dataSave: any
}

const initialState: UseSaveSlice = {
	dataSave: undefined
}

const useSaveSlice = createSlice({
	name: 'save',
	initialState,
	reducers: {
		setUseSave: (state, { payload }: PayloadType<any>) => {
			state.dataSave = payload
		}
	}
})

export default useSaveSlice.reducer
export const { setUseSave } = useSaveSlice.actions
