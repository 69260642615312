import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const sendContactInfo = gql`
	mutation sendContactInfo($input: ContactInfoInput!) {
		sendContactInfo(input: $input) {
			status
		}
	}
`

export const sendContactUs = gql`
  mutation sendContactUs($input: ContactUsInput!) {
		sendContactUs(input: $input) {
			status
		}
	}
`

type TContactInfo = {
	input: {
		name: string
    email: string
		content: string
	}
}

type TContactUS = {
	input: {
		name: string
		email: string
		content: string
    phone_number: string
	}
}

const contactInfo = async (data: TContactInfo) => {
	return await graphQLClient.request(sendContactInfo, {
		...data
	})
}

const contactUS = async (data: TContactUS) => {
	return await graphQLClient.request(sendContactUs, {
		...data
	})
}

export const contactApi = {
	contactInfo,
  contactUS
}

export default contactApi;
