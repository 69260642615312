// api lấy lịch chiếu của 1 suất phim
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TMovie & TFilterParams>('v2/ShowTimes')

export type TRequireShowTime = {
	id_Movie: string
	id_MovieTheater: string
	id_Area: string
	date: string
	id_Server: string
}

export const showTimes = {
	getList: (data: TRequireShowTime) =>
		get<TMovie[]>(``, {
			data: { ...data }
		})
}
