import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { post } = new BaseAPI<TBuyCombo & TFilterParams>('v1/BuyComBo')

export const buyCombo = {
	createBuyCombo: (data: TBuyCombo) => post<any>(``, data)
}
