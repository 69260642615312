import { gql } from 'graphql-request'
import { graphQLClient } from '../graphql'
import BaseAPI from '../methods'

const createMember = (data: any) => {
	const { post } = new BaseAPI<any>(`v1/CreateMember`)
	return post(``, data)
}

const member = (data: any) => {
	const { post } = new BaseAPI<any>(`v1/Member`)
	return post(``, data)
}

const editMember = (data: any) => {
	const { post } = new BaseAPI<any>(`v1/EditMember`)
	return post(``, data)
}

const memberLevel = () => {
	const { get } = new BaseAPI<any>(`v1/MemberLevel`)
	return get(``)
}

const resetPassword = () => {}


export const forgotPass = gql`
	mutation resetUserPassword($input: ResetUserPasswordInput!) {
		resetUserPassword(input: $input) {
			status
		}
	}
`

export const createMemberGql = gql`
mutation CreateMember($input: MemberInput!) {
  createMember(input: $input) {
    message
  }
}

`
const forgotPassword = async (username: any) => {
  const res = await graphQLClient.request(forgotPass, {
		input: { username: username }
  })
  return res
}

const createMemberMagento = async (input: IInfoMember) => {
  const res = await graphQLClient.request(createMemberGql, {
		input: input
  })
  return res
}

export const account = {
	login: member,
	resetPassword,
	edit: editMember,
	level: memberLevel,
  create: createMember,
  forgotPassword:forgotPassword,
  createMemberMagento: createMemberMagento
}