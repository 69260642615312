import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TCombo & TFilterParams>('v1/Goods')

export const goods = {
	getList: () => get<any>(``)
}
