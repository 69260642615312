import { PayloadType } from '@/types'
import { createSlice } from '@reduxjs/toolkit'

interface IUserSlice {
	userInfo?: string
}

const initialState: IUserSlice = {}

const userTempSlice = createSlice({
	name: 'userTemp',
	initialState,
	reducers: {
		setUserTempInfo: (state, action: PayloadType<any>) => {
			state.userInfo = action?.payload.userInfo
		},

		clearUserTempInfo: () => {
			return initialState
		}
	}
})

export default userTempSlice.reducer
export const { setUserTempInfo, clearUserTempInfo } = userTempSlice.actions
