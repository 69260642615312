import { gql } from 'graphql-request';
import { graphQLClient } from './graphql';

export const getCareers = gql`
query getCareers {
  careerCategories {
    items {
      careers {
        title
        short_description
        description
        expired_at
        requirement
        entity_id
        url_key
        position
        cinema_name
      }
      description
      entity_id
      name
    }
  }
}
`

export const getCareersDetail = gql`
query getCareersDetail($url_key: String!) {
  careersDetail(url_key: $url_key) {
    items {
      description
      entity_id
      expired_at
      requirement
      title
      url_key
      position
      cinema_name
    }
  }
}
`

const handleGetCareers = async () => {
  const language = localStorage.getItem('language') ?? 'vn';
  graphQLClient.setHeader('store', language);

  return await graphQLClient.request(getCareers)
}

const handleGetCareersDetail = async (urlKey: string) => {
  const language = localStorage.getItem('language') ?? 'vn';
  graphQLClient.setHeader('store', language);

  return await graphQLClient.request(getCareersDetail, {
    url_key: urlKey
  })
}

const careerApi = {
  getList: handleGetCareers,
  getDetail: handleGetCareersDetail
};

export default careerApi;