import { PayloadType } from '@/types'
import { createSlice } from '@reduxjs/toolkit'

interface IUserSlice {
	userInfo?: string
}

const initialState: IUserSlice = {}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadType<any>) => {
			state.userInfo = action?.payload.userInfo 
		},

		clearUserInfo: () => {
			return initialState;
		}
	}
})

export default userSlice.reducer;
export const { setUserInfo, clearUserInfo } = userSlice.actions
