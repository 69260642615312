// api lấy lịch chiếu của 1 suất phim
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TRequireShowTimePrice & TFilterParams>('v1/ShowTimesPrice')

export type TRequireShowTimePrice = {
	id_ShowTimes: string
	id_Server: string
}

export const showTimesPrice = {
	getList: (data: TRequireShowTimePrice) =>
		get<TRequireShowTimePrice[]>(``, {
			data: { ...data }
		})
}
