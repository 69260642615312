import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'

export const getPayment = gql`
	mutation getOnePayUrl($input: PayOneInput!) {
		getOnePayUrl(input: $input) {
			url
		}
	}
`

export const getPaymentInternational = gql`
	mutation getInternationalOnePayUrl($input: PayOneInput!) {
		getInternationalOnePayUrl(input: $input) {
			url
		}
	}
`

export const getPaymentMomo = gql`
	mutation getMomoUrl($input: MomoInput!) {
		getMomoUrl(input: $input) {
			app_link
			url_pay
			deep_link
			deep_link_mini_app
			qr_code_url
		}
	}
`

export const payment = {
	createPayment: async (data: any) => {
		try {
			return await graphQLClient.request(getPayment, { ...data })
		} catch (error) {
			console.error('Error get list post:', error)
			return error
		}
	},
	createPaymentInternational: async (data: any) => {
		try {
			return await graphQLClient.request(getPaymentInternational, { ...data })
		} catch (error) {
			console.error('Error get list post:', error)
		}
	},
	createPaymentMomo: async (data: any) => {
		try {
			return await graphQLClient.request(getPaymentMomo, { ...data })
		} catch (error: any) {
			console.error('Error get list post:', error)
			return error
		}
	}
}
