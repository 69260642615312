import { config } from '@/config/appConfig'
import axios, {  AxiosResponse } from 'axios'
import { token } from './token'


const apiConfig = {
  baseUrl: `${config.API_URL}`,
}

export const authHeader_X = async () => {
			const res: any = await token?.getToken()
			const tokenDecode: any = atob(res?.getKingProToken?.token) 
			const newToken = tokenDecode


    return newToken ? { Authorization: 'Bearer ' + newToken } : {}
}

const instance = axios.create({
  baseURL: apiConfig.baseUrl,
  headers: {
    "Content-Type": 'application/json',
  },
  timeout: 60000, // 60 seconds
})

export const setToken = (token: string) => {
  instance.defaults.headers.common['Authorization'] =
    'Bearer ' + token
}

// const getUrl = (config: any) => {
//   if (config?.baseURL) {
//     return config?.url.replace(config?.baseURL, '')
//   }

//   return config?.url
// }

// Intercept all request
instance.interceptors.request.use(
  // AxiosRequestConfig
  async (config: any) => {
    const AuthHeader: any = await authHeader_X()
    config.headers = {
      ...config.headers,
      ...AuthHeader,
    }
    // console.log(
    //   `%c ${config?.method?.toUpperCase()} - ${getUrl(config)}:`,
    //   'color: #0086b3; font-weight: bold',
    //   config,
    // )
    return config
  },
  (error) => Promise.reject(error),
)

// THIS FLAG USE TO THROW AN AXIOS CANCEL OPERATION WHEN EVER 401 RESPONSE HAPPEN MORE THAN TWICE
let isAbort = false

// Intercept all responses
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // console.log(
    //   `%c ${response?.status} - ${getUrl(response?.config)}:`,
    //   'color: #008000; font-weight: bold',
    //   response,
    // )

    return response
  },
  (error) => {
    // Phản hồi rồi mà bị lỗi từ phía server ...
    if (error?.response) {
      // console.log("====== LỖI PHÍA SERVER =====");

      if (error?.response?.status === 408) {
        // signOut();
      }
    }

    if (error?.response) {
      // console.log("====== LỖI PHÍA SERVER =====");

      // const { pathname } = window?.location

      const errorMessage = error?.response?.data?.ResultMessage

      const status = error?.response?.status

      const url: string = error?.response?.config?.url

      // ABORT ALL CURRENT REQUESTS
      if (isAbort) {
        throw new axios.Cancel('Detecting 401 response, abort rest requests!')
      }

      switch (status) {
        case 401: {
          // IGNORE WITH SOME ROUTES
          if (url.includes('authenticate/login')) {
            break
          }

          if (typeof window !== 'undefined' && !!localStorage.getItem('accessToken')) {
            // TURN ON ABORT FLAG
            isAbort = true

            // CLEAR BROWSER CACHE
            localStorage.clear()

            // // ALERT SOME INFO TO USER
            window?.alert(errorMessage || 'Phiên đăng nhập hết hạn!')

            break
          }

          break
        }
        case 403: {
          // IGNORE WITH SOME ROUTES

          // TURN ON ABORT FLAG
          isAbort = true

          // ALERT SOME INFO TO USER
          window.alert(
            errorMessage ||
              'Phiên đăng nhập hết hạn hoặc bạn không có quyền truy cập trang này !',
          )

          // REDIRECT USER TO PREVIOUS PAGE
          window.history.back()

          break
        }
      }
    }
    // Lỗi request mãi mà không thấy
    else if (error?.request) {
      // console.log('====== LỖI REQUEST MÃI KHÔNG THẤY =====')
    }
    // Lỗi gì đó ...
    else {
      // console.log('====== LỖI CHƯA XÁC ĐỊNH =====')
    }

    // console.log(
    //   `%c ${error?.response?.status} - ${getUrl(error?.response?.config)}:`,
    //   'color: #a71d5d; font-weight: bold',
    //   error?.response,
    // )
    return Promise.reject(error)
  },
)

export default instance
