import { gql } from 'graphql-request'
import { graphQLClient } from './graphql'
import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TMovie & TFilterParams>('v1/Cinema')

export const cinima = {
	getList: (data: { id_area: string }) =>
		get<TMovie[]>(``, {
			data: { ...data }
		})
}

export const getListCinema = gql`
	query getListCinemas {
		cinemas {
			address
			area_id
			area_name_en
			area_name_vn
			description
			facebook
			fax
			id
			id_server
			id_store
			infor_cinema
			image
			maps
			maps_url
			name_en
			name_vn
			orderby
			telephone
			tiktok
			zalo
      alias_vn
      alias_en
      url_iframe_maps
		}
	}
`

export const getListArea = gql`
	query getListArea {
		areas {
			id
			name_en
			name_vn
			orderby
		}
	}
`

export const listCinema = {
  getListCinema: async () => {
    try {
      return await graphQLClient.request(getListCinema)
		} catch (error) {
      console.error('Error get list post:', error)
    }
  },
  getListArea: async () => {
    try {
      return await graphQLClient.request(getListArea)
    } catch (error) {
      console.error('Error get list post:', error)
    }
  }
}