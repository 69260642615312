import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { post } = new BaseAPI<TBooking & TFilterParams>('v3/Booking')

export const bookingV3 = {
	create: (data: any) => post<TBooking[]>(``, data)
}
