import BaseAPI from './methods'

type TFilterParams = {
  Type: number
}

const { get} = new BaseAPI<TMovie & TFilterParams>(
  'v1/Movie',
)

export const movie = {
  getList: (data: {
    id_Country: string
    id_MovieFormats: string
    id_MovieType: string
    id_Movie: string
    status: string
  }) =>
    get<TMovie[]>(``, {
      data: {...data},
    }),
  
}
