import { persistor, store } from '@/store';
import { TAppPropsWithLayout } from '@/types/layout';
import { QueryClient, QueryClientConfig, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '../styles/swiper.min.css';
import { SWRConfig } from 'swr';
import '../styles/global.css';
import '../styles/global.scss';
// apollo
import { cmsBlock } from '@/services';
import theme from '@/utils/ThemeAntdConfig';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { ConfigProvider } from 'antd';
import { NextSeo } from 'next-seo';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import '../styles/toastify.min.css';

const client = new ApolloClient({
  uri: "http://localhost:3000/",
  cache: new InMemoryCache(),
});

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
}

const queryClient = new QueryClient(queryClientConfig)

const MyApp = ({ Component, pageProps}: TAppPropsWithLayout) => {
  const Layout: any = Component.Layout
  let flag: boolean = false

  useEffect(() => {
    const fetchData = async () => {
      flag = true
      const res: any = await cmsBlock.getCmsBlock({ "identifiers": ["script-footer"] })
      const starDiv = `<div data-content-type="html" data-appearance="default" data-element="main">`
      const endDiv = `</div>`
      const newData = res?.cmsBlocks?.items?.[0]?.content
      const dataReplace: any = newData?.replaceAll('&lt;', "<")?.replaceAll('&gt;', ">")?.replace(starDiv, "")?.replace(endDiv, "")

      const head = document.head;

      // Tạo một phần tử div để chứa HTML content
      const div = document.createElement('div');
      div.innerHTML = dataReplace;

      // Lấy tất cả các thẻ script từ div
      const scriptElements = div.getElementsByTagName('script');

      // Thêm từng thẻ script vào thẻ head
      Array.from(scriptElements).forEach((script) => {
        const newScript = document.createElement('script');
        newScript.innerHTML = script.innerHTML;
        head.appendChild(newScript);
      });
    }
    !flag && fetchData()
  }, [flag]);

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              {() => (
                <SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false }}>
                  <ToastContainer />
                  <NextSeo defaultTitle='Cinestar'/>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </SWRConfig>
              )}
            </PersistGate>
          </ConfigProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default MyApp
