import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { get } = new BaseAPI<TSeat & TFilterParams>('v2/Seats')

export const seats = {
	getList: (data: { id_ShowTimes: string; id_Server: string }) =>
		get<TSeat[]>(``, {
			data: { ...data }
		})
}
