import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request'
import { toast } from 'react-toastify'

const requestMiddleware: RequestMiddleware = async (request) => {
  const language = (typeof window !== "undefined") ? localStorage.getItem('language') : 'vn';

  return {
    ...request,
    headers: {
      store: language ?? 'vn',
      ...request.headers,
    },
  }
}

const responseMiddleware: ResponseMiddleware = (response: any) => {
  if (!(response instanceof Error) && response.errors) {
    if (response.errors[0]?.extensions?.category === 'graphql-authorization') {
      localStorage.clear()
      window.location.replace(`${window.location.origin}/account`)
    }

    const errorMessage = response?.errors[0]?.message
    const errorPath = response?.errors[0]?.path?.[0]
    if (errorMessage && errorPath !== 'cmsBlocks') {
      toast.error(errorMessage)
      
		throw Error(errorMessage)
	}
  }
  return {
    ...response,
  }
}

const graphQLClient = new GraphQLClient(`${process.env.NEXT_PUBLIC_API_URL_MAGENTO}`, {
  requestMiddleware,
  responseMiddleware,
  errorPolicy: 'all',
})

const graphQLClientGet = new GraphQLClient(`${process.env.NEXT_PUBLIC_API_URL_MAGENTO}`, {
  requestMiddleware,
  responseMiddleware,
  errorPolicy: 'all',
  method: 'GET',
})

if (typeof window !== 'undefined') {
  graphQLClient.setHeaders({
    Authorization:
      localStorage && localStorage.getItem('token')
        ? 'Bearer ' + localStorage.getItem('token')
        : '',
    'Content-Type': 'application/json',
    accept: 'application/json',
  })
}
// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken)
    graphQLClient.setHeaders({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      accept: 'application/json',
    })
  } else {
    localStorage.removeItem('token')
    graphQLClient.setHeaders({
      Authorization: '',
      'Content-Type': 'application/json',
      accept: 'application/json',
    })
  }
}

export const setTokenGraphQL = (token: string) => {
  graphQLClient.setHeaders({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    accept: 'application/json',
  })
}

export { graphQLClient, graphQLClientGet }
