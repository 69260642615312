import BaseAPI from './methods'

type TFilterParams = {
	Type: number
}

const { post } = new BaseAPI<TCombo & TFilterParams>('v1/BookingAddCoupon')

export const comboApply = {
	applyCombo: (data: { barcode: string; code: string; box: string; con: string; id_server: string }) => post<any>(``, { ...data })
}
