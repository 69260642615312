import { saveLog } from './save-log'

type TSaveLog = Partial< {
	action: string
	page_name: string
	page_url: string
	session_key: string
	status: string
	barcode: string
	error_message: string
	information: string
	request_json: string
	step: string
}>
// Gửi log khi request về kingpro bị lỗi
export const sendLog = async (data: TSaveLog) => {
	const formData = {
		input: {
			action: data.action || '',
			page_name: data.page_name || '',
			page_url: data.page_url || '',
			session_key: data.session_key || '',
			status: data.status || '',
			error_message: data.error_message || '',
			information: data.information || '',
			request_json: data.request_json || ''
		}
  }
	await saveLog.save(formData)
}
